@import "../../../../scss/variables";

.mobile-header {
    // height: 133px;
    height: 85px;
    &-wrapper {
        flex-direction: column;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 102;
        box-shadow: 0px 1px 14px -1px rgba(40, 40, 40, 0.5);
        // outline: 2px solid $secondary-color;
        // border-bottom: 2px solid $primary-color;
    }

    &-top {
        height: 35px;
        background-color: $top-header;
        color: #ffffff;
        align-items: center;

        span {
            font-family: Montserrat-Bold;
            letter-spacing: 0.39px;
            text-transform: uppercase;
            font-size: 10px;
            @media screen and (max-width: "576px") {
                font-size: 8px;
            }
        }

        .pin {
            svg {
                width: 12px;
                height: 12px;
                margin-right: 7px;
                cursor: pointer;
            }
            &-link {
                cursor: pointer;
                font-family: Montserrat-SemiBold;
                font-size: 10px;
                line-height: 1.83;
                letter-spacing: 0.28px;
                color: #ffffff;
            }
        }
        .shop {
            svg {
                margin-right: 7px;
            }
            &-link {
                color: #ffffff;
                text-decoration: none;
                // &:hover {
                //     text-decoration: unset;
                // }
            }
        }
    }

    &-bottom {
        padding-right: 6px;
        height: 69px;
        background-color: #ffffff;
        border-bottom: 1px solid #edeaea;
        align-items: center;
        .logo {
            width: 139px;
            svg {
                width: 120px;
                height: auto;
            }
            img {
                max-width: 100%;
            }
        }
        .icons {
            display: flex;
            justify-content: flex-end;
            align-items: center;
        }
        .header-item {
            svg {
                width: 20px;
                height: 20px;
            }
        }
        .user {
            &-logged {
                cursor: pointer;
                border: solid 1px #3c3c3b;
                border-radius: 50%;
                font-family: Montserrat-SemiBold;
                font-size: 9px;
                letter-spacing: -0.51px;
                width: 22px;
                height: 22px;
                color: #333333;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
    }

    &-search {
        padding-left: 12px;
        padding-right: 16px;
        height: 48px;
        background-color: #ffffff;
        display: flex;
        padding-top: 9px;
        padding-bottom: 9px;
        border-bottom: 1px solid #edeaea;
        &-icon {
            width: 18px;
            height: 18px;
        }

        form {
            display: flex;
            width: 100%;
            margin-top: unset;
        }

        input {
            // -webkit-appearance: none;
            height: 30px;
            padding-left: 8px;
            padding-right: 5px;      
            border-radius: unset;      
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            border: 1px solid #d8d8d8;
            width: 100%;
            border-right: none;
            font-size: 12px;
            &::placeholder {
                letter-spacing: 0.12px;
                font-size: 12px;
                color: $top-header-search;
            }
        }
        button {
            width: 70px;
            height: 30px;
            border-radius: unset;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            span {
                color: #ffffff;
                letter-spacing: 0.87px;
                font-size: 12px;
                font-family: Montserrat-Bold;
                text-transform: uppercase;
                padding-left: unset;
            }
        }
    }
}