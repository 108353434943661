.address-select {
    input {
        background: none;
        background-color: white;
        padding: .375rem .75rem;
        padding-left: 0;
        display: block;
        width: 100%;
        border: none;
        border-radius: 0;
        border-bottom: 1px solid #ced4da;
        font-family: Montserrat-Medium;
        font-size: 14px;
        letter-spacing: 0.6px;
        color: #212528;
        &::placeholder {
            font-family: Montserrat-Regular;
            font-size: 12px;
            letter-spacing: 0.6px;
            color: #7a7a7a;
        }
        &:disabled {
            background-color: rgba(204, 204, 204, 0.349);
        }
    }
    .input-required {
        border-bottom: 1px solid #dd0202!important;
    }
    .dropdown-items {
        z-index: 99;
    }
}