@import "../../../../scss/variables";

.header {
    height: 114px;
    &-wrapper {
        flex-direction: column;
        left: 0;
        position: fixed;
        right: 0;
        top: 0;
        z-index: 102;
        background-color: #fafafa;
        max-width: 2000px;
        margin: 0 auto;
        box-shadow: 0px 1px 14px -1px rgba(40, 40, 40, 0.5);
        // outline: 2px solid $secondary-color;
        // border-bottom: 2px solid $primary-color;
    }

    &-top {
        padding-left: 50px;
        padding-right: 50px;
        height: 40px;
        background-color: $top-header;
        color: #ffffff;
        align-items: center;

        span {
            font-family: Montserrat-Bold;
            font-size: 12px;
            letter-spacing: 0.1px;
            text-transform: uppercase;
            &.status {
                text-transform: lowercase;
                font-family: Montserrat-ExtraBold;
            }
        }
        .pin {
            svg {
                width: 14px;
                height: 18.4px;
                margin-right: 7px;
            }
            &-link {
                font-size: 12px;
                line-height: 1.83;
                letter-spacing: 0.28px;
                color: #ffffff;
            }
        }
        .order {
            flex-grow: 1;
            text-align: center;
            font-family: Montserrat-Regular;
            font-size: 12px;
            line-height: 1.83;
            letter-spacing: 0.48px;
            color: #ffffff;
            &-link {
                text-decoration: none;
                span {
                    font-size: 14px;
                    font-family: Montserrat-Bold;
                    letter-spacing: 0.56px;
                    color: #ffffff;
                }
            }
        }
        .shop {
            svg {
                margin-left: 7px;
            }
            &-link {
                color: #ffffff;
                text-decoration: none;
                // &:hover {
                //     text-decoration: unset;
                // }
            }
        }
    }

    &-bottom {
        padding-left: 50px;
        padding-right: 40px;
        height: 80px;
        
        background-color: #ffffff;
        align-items: center;
        .logo {
            // padding-bottom: 6px;
            width: 170px;
            img {
                max-width: 100%;
                width: auto;
            }
            svg {
                width: 200px;
                height: auto;
            }
        }
        
        .search {
            display: flex;
            padding-bottom: 6px;
            form {
                display: flex;
                width: 100%;
                margin-top: unset;
            }
            input {
                height: 45px;
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                border: 1px solid #d8d8d8;
                width: 100%;
                border-right: none;
                padding-left: 11px;
                padding-right: 5px;
                &::placeholder {
                    letter-spacing: 0.12px;
                    font-size: 14px;
                    color: $top-header-search;
                }
            }
            button {
                height: 45px;
                border-radius: unset;
                border-top-right-radius: 8px;
                border-bottom-right-radius: 8px;
                width: 99px;
                border: none;
                margin-bottom: unset;
                span {
                    padding: unset;
                }
            }
        }
        .user {
            svg {
                cursor: pointer;
                width: 20px;
                height: 20px;
            }
            &-logged {
                cursor: pointer;
                border: solid 1px #3c3c3b;
                border-radius: 50%;
                font-family: Montserrat-SemiBold;
                font-size: 10px;
                width: 24px;
                height: 24px;
                color: #333333;
                display: flex;
                justify-content: center;
                align-items: center;
                margin: auto;
            }
        }
        .actions {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            svg {
                cursor: pointer;
                width: 22px;
                height: 22px;
            }
            // svg path {
            //     fill: #333333!important;
            // }
        }
    }
}

