@import "../../../scss/variables";

.footer {
    position: relative;
    bottom: 0;
    width: 100%;
    &-content {
        min-height: 250px;
        background-color: #d8d8d8;
        color: #ffffff;   
        @media screen and (max-width: 991px) {
            padding-top: 24px;
            padding-bottom: 20px;
        }
        @media screen and (max-width: 767px) {
            padding-top: 40px;
            padding-bottom: 20px;
        }
        padding-top: 38px;
        padding-bottom: 55px;
        .logo {
            img {
                width: 170px;
            }
            svg {
                width: 180px;
                height: 100px;
                @media screen and (max-width: 991px) {
                    margin-bottom: 20px;
                }
            }
        }
        .row {
            padding-left: 45px;
            padding-right: 25px;
            @media screen and (max-width: 991px) {
                padding-left: 39px;
                padding-right: 12px;
            }
        }
    }

    &-contacts {
        margin-top: 30px;
        @media screen and (max-width: 992px) {
            margin-top: 10px;
            margin-bottom: 20px;
        }
        .contacts-title {
            color: #333333;
            font-family: Montserrat-Bold;
            font-size: 14px;
            line-height: 1.38;
            letter-spacing: 1.09px;
        }
        .contacts-subtitle {
            color: #333333;
            font-family: Montserrat-Regular;
            font-size: 12px;
            line-height: 1.38;
            letter-spacing: 1.09px;
            margin-top: 10px;
        }
        .element {
            display: flex;
            align-items: center;
            color: #333333;
            font-family: Montserrat-Bold;
            font-size: 16px;
            line-height: 1.38;
            letter-spacing: 1.09px;
            margin-top: 10px;
            @media screen and (max-width: 992px) {
                align-items: unset;
                justify-content: center;
                height: 20px;
            }
            a {
                color: #333333;
            }
        }
        svg {
            width: 25px!important;
            height: 25px!important;
            margin-right: 20px;
        }
    }

    &-follow {
        border-top: 1px solid #d8d8d8;
        margin-left: 30px;
        margin-right: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 26px;
        .footer-text {
            padding-bottom: unset;
            margin-right: 10px;
        }
    }

    &-bottom {
        height: 50px;
        @media screen and (max-width: 567px) {
            height: 99px;
        }
        background-color: $footer-bottom;
        padding-left: 45px;
        padding-right: 45px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        span {
            font-family: Montserrat-Regular;
            font-size: 12px;
            color: #ffffff;
            .bold {
                font-family: Montserrat-Bold;
            }
        }
        .powered {
            @media screen and (max-width: 567px) {
                padding-bottom: 10px;
            }
        }
    }
    .footer-p-iva {
        padding-left: 48px;
    }

    &-link {
        font-family: Montserrat-Regular;
        font-size: 14px;
        letter-spacing: 0.82px;
        color: #e41b23;
        padding-bottom: 8px;

    }
    &-links {
            text-decoration: none;
            &:hover {
                text-decoration: none !important;
                color: #e41b23 !important;
            }
    }

    &-text {
        font-family: Montserrat-Regular;
        font-size: 16px;
        line-height: 1.38;
        letter-spacing: 1.09px;
        color: #333333;
        margin-bottom: 16px;
        display: flex;
        @media screen and (max-width: 991px) {
            justify-content: center;
        }
        .credits {
            font-family: Montserrat-Regular;   
            padding-bottom: 13px;
            border-bottom: 1px solid #333333;
            @media screen and (max-width: 991px) {
                padding-top: 30px;
            }
        }
    }

    &-apps {
        padding-right: 10px;
        padding-bottom: 12px;
        @media screen and (max-width: 767px) {
            display: flex;
            justify-content: center;
        }
        @media screen and (max-width: 576px) {
            display: unset;
            justify-content: unset;
        }
        button {
            @media screen and (max-width: 576px) {
                width: 100%;
            }
            @media screen and (max-width: 767px) {
                margin-right: 10px;
            }
        }
        a {
            text-decoration: none;
        }

    }

    &-social {
        display: flex;
        @media screen and (max-width: 991px) {
            justify-content: center;
            padding-top: 30px;
            padding-bottom: 10px;
        }
        &-icon {
            margin-right: 15px;
        }
    }
}