.product-info {
    width: 100%;
    min-height: 425px;
    background-color: #9999991A;
    padding-left: 53px;
    padding-right: 53px;
    @media screen and (max-width: "576px") {
        padding-left: 15px;
        padding-right: 15px;
    }
    padding-top: 40px;
    padding-bottom: 40px;

    font-family: Montserrat-Regular;
    font-size: 14px;
    letter-spacing: 0.6px;
    color: #333333;

    b {
        font-family: Montserrat-Bold;
    }

    &-title {
        font-family: Montserrat-Bold;
        font-size: 14px;
        letter-spacing: 0.6px;
        color: #333333;
        text-transform: uppercase;
        border-bottom: 1px solid #333333;
        padding-bottom: 7px;
        margin-bottom: 17px;
    }
    table, td, tr, th {
        border: solid 1px #979797;
    }
    table {
        @media screen and (max-width: 768px) {
            width: 100%;
        }
    }
    td, tr, th {
        height: 33px;
        padding-left: 11px;
        padding-right: 17px;
    }
    th {
        font-family: Montserrat-SemiBold;
        font-size: 14px;
        letter-spacing: 0.6px;
        color: #333333;
    }
    &-allergy {
        margin-bottom: 20px;
        .allergy-title {
            font-family: Montserrat-Bold;
            font-size: 14px;
            letter-spacing: 0.6px;
            color: #333333;
        }
    }
}