@import './scss/_variables.scss';

input:active,
select:active,
textarea:active,
button:active {
    outline: none;
}

.outline,
.outline:hover,
.outline:focus,
.outline:active {
    outline: none;
}

input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}

input:hover,
select:hover,
textarea:hover,
button:hover {
    outline: none;
}

a:hover {
    color: $primary-color!important;
}

.link {
    color: #212529;
    text-decoration: none;
}

.link:hover {
     color:#212529; 
     text-decoration:none; 
     cursor:pointer;  
}

.g-padding {
    padding-left: 20px!important;
    padding-right: 20px!important;
    @media screen and (min-width: 992px) {
        padding-left: 56px!important;
        padding-right: 56px!important;
    }
}

.g-lg-padding {
    @media screen and (min-width: 1500px) {
        padding-left: 56px;
        padding-right: 56px;
    }
}

.button-clean {
    margin: 0;
    padding: 0;
    background: transparent;
    border: 0;
    cursor: pointer;
    outline: none;
    color: inherit;
}

.primary-text {
    color: $primary-color;
    font-family: Montserrat-Bold;
    font-size: 14px;
    text-transform: uppercase;
    text-decoration: none;
}

.primary-color {
    color: $primary-color;
}

.truncate {
    --max-lines: 2;
    position: relative;
    max-height: calc(var(--lh) * var(--max-lines));
    padding-right: 1rem; /* space for ellipsis */
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
    overflow: hidden;
  }

// bootstrap ovverides
$input-focus-box-shadow: white;
$input-focus-border-color: #ced4da;
$input-disabled-bg: white;
$primary: $primary-color;

// bootstrap
@import '../node_modules/bootstrap/scss/bootstrap.scss';

// toasted
@import '../node_modules/toasted-notes/src/styles.css';

// react-image-gallery
@import "~react-image-gallery/styles/scss/image-gallery.scss";

// react-toastify
@import '../node_modules/react-toastify/dist/ReactToastify.css';

// react-datepicker
@import "../node_modules/react-datepicker/dist/react-datepicker.css";
@import "../node_modules/react-datepicker/dist/react-datepicker-cssmodules.css";

// react loader spinner
@import "../node_modules/react-loader-spinner/dist/loader/css/react-spinner-loader.css";

// react slick
@import "~slick-carousel/slick/slick.css";
@import "~slick-carousel/slick/slick-theme.css";

// react smartbanner
@import '~react-smartbanner/dist/main.css';

// fonts
@font-face {
    font-family: "Montserrat-Bold"; 
    src: url("../src/fonts/Montserrat-Bold.ttf");  
}
@font-face {
    font-family: "Montserrat-Regular"; 
    src: url("../src/fonts/Montserrat-Regular.ttf");  
}
@font-face {
    font-family: "Montserrat-Medium"; 
    src: url("../src/fonts/Montserrat-Medium.ttf");  
}
@font-face {
    font-family: "Montserrat-ExtraBold"; 
    src: url("../src/fonts/Montserrat-ExtraBold.ttf");  
}
@font-face {
    font-family: "Montserrat-SemiBold"; 
    src: url("../src/fonts/Montserrat-SemiBold.ttf");  
}

body {
    margin: 0;
    padding: 0;
    font-family: Montserrat-Regular;
    max-width: 100%;
}

h1 {
    font-family: Montserrat-Bold;
    margin: unset;
    padding: unset;
}

h5 {
    font-family: Montserrat-Regular;
    margin: unset;
    padding: unset;
}

.slick-dots {
    bottom: -22px;
    li {
        button {
            font-size: 12px;
            &::before {
                font-size: 12px;
            }
        }
    }
}
.slick-dots li.slick-active button:before {
    color: $primary-color;
}

.mobile-view-search {
    // height: stretch;
    // height: -webkit-fill-available;
    // height: -moz-fill-available;
    @media screen and (max-width: "991px") {
        padding-top: 67px;
    }
}

.mobile-profile-container {
    @media screen and (max-width: "991px") {
        padding-top: 95px;
    }
}

.mobile-checkbox {
    @media screen and (max-width: "576px") {
        margin-left: 20px;
    }
}

ul {
    padding-inline-start: unset
}

.main-container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    max-width: 2000px;
    margin: 0 auto;
}

.content {
    flex: 1;
   // padding-bottom: 300px;
}

.navigation {
    height: 85px;
    position: relative;
    z-index: 101;
}

.navigation-wrapper {
    // display: block;
    flex-direction: column;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 102;
}

.navigation-bar {
    background: #fff;
    border-bottom: 2px solid #f7f7f7;
    height: 85px;
    position: relative;
    z-index: 103;
    min-width: 320px;
}

.navigation-column {
    flex-grow: 1;
}

.navigation-logo {
    // height: 85px;
    // width: 192px;
    // Coop-Puglia
    height: 85px;
    width: 120px;
    svg {
        width: 253px;
        height: 41px;
    }
}

.navigation-logo img {
    max-width: 100%;
    width: auto;
}

.logo-parent {
    padding-left: 56px;
}

.navigation-logo-break {
    display: none;
    width: 100%;
}

.navigation-item {
    display: block;
    text-decoration: none;
    cursor: pointer;
}

.navigation-item-anchor {
    cursor: pointer;
    font-family: Montserrat-Bold;
    user-select: none;
}

.navigation-box {
    height: 83px;
    cursor: pointer;
    font-family: Montserrat-Bold;
    font-size: 10px;
    margin-right: 1px;
    position: relative;
    text-transform: uppercase;
    user-select: none;
    min-width: 50px;
    width: 83px;
    @media screen and (max-width: "991px") {
        height: 55px;
        width: 25px;
        min-width: unset;
        margin-left: 13px;
        .navigation-box__icon {
            svg {
                width: 20px;
                height: 20px;
            }
        }
    }

    &:hover {
        color: $primary-color;
        .navigation-box__icon {
            svg {
                path {
                    fill: $primary-color;
                }
            }
        }
    }

    &__active {
        @media screen and (min-width: "992px") {
            background: $primary-color;
            color: #fff;
            &:hover {
                color: #fff;
                .navigation-box__icon {
                    svg {
                        path {
                            fill: white;
                        }
                    }
                }
            }
            .navigation-box__icon {
                svg {
                    path {
                        fill: white;
                    }
                }
            }
        }
    }
}

.navigation-box__wrapper {
    height: 60px;
    margin-top: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
    @media screen and (max-width: "991px") {
        height: unset;
        top: 30%;
    }
}

.navigation-box__spacer {
    height: 6px;
    width: 100%;
    @media screen and (max-width: "991px") {
        height: 35px;
        padding-top: 22px;
    }
}

.navigation-box__icon {
    height: 27px;
    margin-bottom: 4px;
    position: relative;
    width: 100%;
}

.navigation-rect {
    background: $primary-color;
    color: #fff;
    cursor: pointer;
    font-family: Montserrat-Bold;
    font-size: 10px;
    height: 83px;
    position: relative;
    text-transform: uppercase;
    user-select: none;
    width: 170px;
}

.navigation-rect__wrapper {
    height: 30px;
    margin-top: auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
}

.navigation-rect__icon {
    display: inline-block;
    height: 100%;
    vertical-align: top;
    width: 22px;
    position: relative;
    margin-right: 27px;
    padding-top: 5px
}

.navigation-rect__label {
    display: inline-block;
}

.navigation-view {
    padding-top: 40px;
    padding-bottom: 48px;
    margin: 0 auto;
    max-width: 990px;
    width: 100%;
}

.category-navigation-view {
    margin: 0 auto;
    width: 100%;
    padding-left: 5%;
    .column {
        padding-bottom: 8px;
    }
    @media screen and (max-width: "576px") {
        padding-left: 30px;
    }
}

.Modal {
    top: 106px; // balancing header height
    @media screen and (max-width: 992px) {
        top: 90px; // balancing header height
    }
    position: fixed;
    z-index: 500;
    background-color: #ffffff;
    color: black;
    width: 100%;
    box-shadow: 0.3px 0.3px 0.3px #f7f7f7;
    box-sizing: border-box;   
    max-height: 100%;
    border-top: 1px solid #edeaea;
    @media screen and (max-width: "576px") {
        overflow-y: auto;
        padding-top: unset!important;
        // height: 600px;
        max-height: 600px;
    }
}

.modal-mobile-scrolling {
    @media screen and (max-width: "576px") {
        overflow-y: auto;
        height: 600px;
    }
}

.modal-close {
    color: #333;
    font-size: 35px;
    // float: right;
    background-color: #ffffff;
    border: none;
}

.Backdrop {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 100;
    left: 0;
    background-color: rgba(0,0,0,0.5);
}

.Carousel div {
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 330px;
    // @media screen and (max-width: "1199px") {
    //     height: 330px;
    // }
    @media screen and (max-width: "991px") {
        height: 325px;
    }
    @media screen and (max-width: "575px") {
        height: 230px;
    }
}

.wrapper {    
    @media screen and (max-width: "576px") {
        overflow-y: auto;
        max-height: 90vh;
    }

    padding-top: 111px;
    &-box {
        border-radius: 10px;
        box-shadow: 0 0 13px 6px rgba(0, 0, 0, 0.11);
        background-color: #ffffff;
        padding: 20px;
    }
}

.signup-wrapper {
    margin-top: 60px;
    margin-bottom: 40px;
}

.signup-form {
    max-width: 550px;
    width: 100%;
    margin: 0 auto;
}

.signup-form-title {
    font-family: Montserrat-Bold;
    font-size: 14px;
    text-transform: uppercase;
    margin-bottom: 33px;
    display: inline-block;
}

.signup-row {
    margin-bottom: 21px;
}

.custom-input {
    border-top: 0;
    border-right: 0;
    border-left: 0;
    border-bottom: 1px solid #ced4da;
    border-radius: 0;
    background: transparent;    
    font-size: 14px;
    line-height: 1.5;
    padding: .375rem .75rem;
    padding-left: 0;
    width: 100%;
    font-family: Montserrat-Medium;
    font-size: 14px;
    letter-spacing: 0.6px;
    color: #212528;
}

.custom-input-placeholder {
    position: absolute;
    pointer-events: none;
    left: 100;
    top: .375rem;
    transition: 300ms ease all;
    font-family: Montserrat-Regular;
    font-size: 12px;
    letter-spacing: 0.6px;
    color: #7a7a7a;
    white-space: nowrap;
    -webkit-transition: all .2s ease;
    -o-transition: all .2s ease;
    transition: all .2s ease;
}

.custom-input-placeholder-elevated {
    top: -13px;
    font-size: 12px;
}

.custom-control {
    min-height: 16vh;
    @media screen and (max-width: "576px") {
        min-height: 20vh;
    }
}

.custom-control-label {
    font-family: Montserrat-Regular;
    font-size: 14px;
    letter-spacing: 0.6px;
    color: #333333;
}

.custom-control-input:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: $primary-color;
    background-color: $secondary-color;
}

.form-control-invalid {
    width: 100%;
    color: #dc3545;
    font-size: 10px;
}

.signup-textarea {
    resize: none;
    padding: 15px;
    font-size: 13px;
    font-family: Montserrat-Bold;
}

.signup-textarea:read-only {
    background-color: #ffffff
}

.signup-radio-label {
    // background-image: url();
    background-repeat: no-repeat;
    font-family: Montserrat-Regular;
    background-size: 15px 15px;
    background-position: 0;
    padding-left: 8px;
    margin: 0;
    margin-right: 15px;
    cursor: pointer;
}

.cta {
    @extend .outline;
    width: 204px;
    height: 45px;
    border-radius: 8px;
    background-color: $primary-color;
    margin-bottom: 20px;
    border: none;
    text-transform: uppercase;
    &:hover {
        background-color: #c51720;
    }
    span {
        color: #ffffff;
        font-family: Montserrat-Bold;
        font-size: 14px;
        letter-spacing: 0.1px;
    }
    img {
        width: 15px;
        height: auto;
        margin-right: 10px;
    }
    svg {
        margin-right: 10px;
        @media screen and (max-width: "576px") {
            margin-right: unset;
        }
        path {
            fill: #ffffff;
        }
    }
    a {
        text-decoration: none;
    }
    &:disabled {
        background-color: #c1c1c1;
        pointer-events: none;
    }
}

.facebook-cta {
    @extend .outline;
    width: 300px;
    height: 45px;
    border-radius: 8px;
    background-color: #1877f2;
    border: none;
    text-transform: uppercase;
    &:hover {
        background-color: #1260C4;
    }
    span {
        color: #ffffff;
        padding-left: 10px;
        font-family: Montserrat-Bold;
        font-size: 14px;
        letter-spacing: 0.1px;
    }
    img {
        width: 15px;
        height: auto;
    }
    svg {
        path {
            fill: #ffffff;
        }
    }
    a {
        text-decoration: none;
    }
}

.custom-button {
    background: $primary-color;
    border: 0;
    color: #fff;
    cursor: pointer;
    font-family: Montserrat-Bold;
    text-transform: uppercase;
    font-size: 12px;
    height: 50px;
    line-height: 50px;
    width: 100%;
}

.custom-slist-button {
    background: #fff;
    border: 0;
    color: #333;
    cursor: pointer;
    font-family: Montserrat-Regular;
    font-size: 13px;
    height: 50px;
    line-height: 50px;
    width: 100%;
}

.custom-slist-button :hover {
    background: #fff;
}

.custom-button-disabled {
    background: #c1c1c1;
    pointer-events: none;
}

.button-clean-disabled {
    color: #c1c1c1;
    pointer-events: none;
}

.custom-button-hide {
    display: none;
}

.title {
    font-family: Montserrat-Bold;
    font-size: 24px;
    letter-spacing: 1.03px;
    @media screen and (max-width: "576px") {
        font-size: 22px;
    }
}

.subtitle {
    color: #333;
    font-family: Montserrat-Regular;
    font-size: 16px;
    margin-bottom: 15px;
}

.login-padding {
    padding-top: unset;
    @media screen and (max-width: "576px") {
        padding-top: 40px;
    }
}

.login-form-paragraph {
    font-size: 12px;
    margin-bottom: 40px;
}

.login-link {
    color: $primary-color;
    font-family: Montserrat-Bold;
    font-size: 14px;
    text-decoration: none;
    text-transform: uppercase;
}

.login-link:hover {
    color: $primary-color; 
    text-decoration: none; 
    cursor: pointer;  
}

.login-icon {
    padding-top: 7px
}

.modal-profile-welcome {
    font-family: Montserrat-Bold;
    font-size: 18px;
    @media screen and (max-width: 576px) {
        font-size: 16px;
    }
    letter-spacing: 0.77px;
    color: #333333;
    margin-bottom: 10px;
}

.modal-profile-name {
    font-family: Montserrat-Bold;
    font-size: 36px;
    @media screen and (max-width: 576px) {
        font-size: 26px;
    }
    letter-spacing: 1.54px;
    text-transform: uppercase;
    color: #333333;
    margin-bottom: 24px;
}

.modal-profile-wrapper {
    max-width: 800px;
    margin: 0 auto;
    .col {
        @media screen and (max-width: 576px) {
            margin-bottom: 15px;
        }
    }
}

.modal-profile-field {
    font-family: Montserrat-Bold;
    font-size: 14px;
    letter-spacing: 0.6px;
    text-align: center;
    color: #333333;
    .modal-link {
        text-decoration: none;
        text-transform: uppercase;
        color: #333333;
    }
}

.smartbip-datepicker {
    font-family: Montserrat-Regular;
    font-size: .8rem;
    background-color: #fff;
    position: relative;
}

.react-datepicker-wrapper {
    width: 100%;
}

.react-datepicker__day--selected,
.react-datepicker__day--keyboard-selected, 
.react-datepicker__day--in-selecting-range, 
.react-datepicker__day--in-range, 
.react-datepicker__close-icon::after {
    background-color: #7c7c7c!important;
}

.featured-section-title {
    font-family: Montserrat-Bold;
    text-align: center;
    font-size: 25px;
    padding-top: 40px;
    padding-bottom: 20px;
    color: $primary-color;
    @media screen and (max-width: 575px) {
        font-size: 20px;
    }
}

.search-product-view {
    padding-bottom: 48px;
    margin: 0 auto;
    padding-top: 40px;
    width: 100%;
}

.search-product {
    height: 100px;
    color: #333;
    font-family: Montserrat-Regular;
    text-decoration: none;
    margin-bottom: 10px;
    margin-top: 5px;
}

.search-product-description {
    color: #333;
    font-family: Montserrat-Regular;
    text-decoration: none;
    padding-top: 40px
}

.search-products-image {
    background: #fff;
    position: relative;
    height: 100px;
    width: 100px;
}

.search-products-image img {
    left: 50%;
    max-height: 65%;
    max-width: 65%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}

.search-row-handling {
    justify-content: center; 
}

.search-col-handling {
    flex: 0 0 50%;
    max-width: 47%;
    @media screen and (min-width: 650px) {
        flex: 0 0 33%;
        max-width: 33%;
    }
    @media screen and (min-width: 768px) {
        flex: 0 0 50%;
        max-width: 47%;
    }
    @media screen and (min-width: 992px) {
        flex: 0 0 33%;
        max-width: 33%;
    }
    @media screen and (min-width: 1200px) {
        flex: 0 0 25%;
        max-width: 25%;
    }
    @media screen and (min-width: 1500px) {
        flex: 0 0 20%;
        max-width: 20%;
    }
}

.view-container {
    padding: 32px;
}

.cart-menu-container {
    padding-top: 39px;
    padding-left: 15px;
    @media screen and (max-width: 1200px) {
        padding-left: 30px;   
    }
}

.cart-container {
    padding-left: 50px;
    padding-top: 39px;
    @media screen and (max-width: 567px) {
        padding-left: 0px;   
        padding-top: 60px;
    }
    @media screen and (min-width: 568px) and (max-width: 991px) {
        padding-left: 0px;   
        padding-top: 70px;
    }
}

.cart-lists {
    cursor: pointer;
    min-height: 50px;
    line-height: 50px;
    color: #333;
    font-family: Montserrat-Regular;
    text-decoration: none;
    margin-bottom: 20px;
    margin-top: 5px;
}

.cart-menu-title {
    font-family: Montserrat-Bold;
    font-size: 14px;
    letter-spacing: 0.6px;
    color: #333333;
    margin-bottom: 16px;
}

.cart-message {
    font-family: Montserrat-Regular;
    font-size: 14px;
    letter-spacing: 0.6px;
    color: #333333;
}

.cart-menu-message {
    font-family: Montserrat-Bold;
    font-size: 18px;
    letter-spacing: 0.77px;
    color: #333333;
}

.cart-actions-spacer {
    padding: 25px 0 45px;
}

.cart-actions-link {
    font-family: Montserrat-Bold;
    text-transform: uppercase;
    color: $primary-color;
    font-size: 14px;
    letter-spacing: 0.1px;
    text-decoration: none; 
}

.cart-actions-link:hover {
    text-decoration: underline; 
    cursor: pointer;  
}

.cart-heading {
    font-family: Montserrat-Medium;
    font-size: 12px;
    letter-spacing: 0.51px;
    color: #333333;
    text-transform: uppercase;
    padding-bottom: 16px;
    border-bottom: 1px solid #d8d8d8;
    @media screen and (min-width: 1200px) {
        padding-right: 6px; // balancing scrollbar width
    }
}

.cart-product-image {
    height: 65px;
    width: 65px;
    background: #fff;
    position: relative;
}

.cart-product-image img {
    left: 50%;
    max-height: 50px;
    max-width: 50px;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}

.cart-product-details {
    position: relative;
    top: 30%;
    padding : 0px 0px 0px 0px;
    font-size: 14px;
    font-family: Montserrat-Regular;
}

.cart-product-total {
    font-size: 14px;
    font-family: Montserrat-Bold;
}

.cart-product-description {
    // white-space: nowrap;
    overflow: hidden;
    -o-text-overflow: ellipsis;
    text-overflow: ellipsis;
    margin: 0;
}

.cart-product-description__promo {
    text-transform: uppercase;
    font-family: Montserrat-Bold;
    font-size: 10px;
    line-height: 2.2;
    letter-spacing: 0.15px;
    color: $primary-color;
}

.cart-item-scrollable {
    @media screen and (min-width: 1200px) {
        overflow-y: auto;
        max-height: calc(75vh - 115px); // max vh - header height
        margin-bottom: 10px;
    
        &::-webkit-scrollbar {
            width: 6px;
            height: 126px;
        }
        
        &::-webkit-scrollbar-track {
            border-radius: 5px;
            background-color: #d8d8d8;
        }
        
        &::-webkit-scrollbar-thumb {
            border-radius: 5px;
            background-color: $secondary-color;
        }
    }
}

.card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.1);
    border-radius: 9px;
    border: none;
    cursor: pointer;
    .card-header {
        background-color: unset;
    }
}

.card-title {
    font-size: 20px;
    @media screen and (max-width: 1199px) {
        font-size: 14px;
    }
}

.card-body {
    background-color: #ffffff;
    transition: 0.3s;
    padding-top: 21px;
    margin-left: 15px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border: none;
}

.card-text {
    font-family: Montserrat-Regular;
    font-size: 12px;
    letter-spacing: 0.51px;
    color: #333333;
    b {
        font-size: 14px;
        font-family: Montserrat-Bold;
        letter-spacing: 0.6px;
        text-transform: uppercase;
        color: #333333;
    }
    &-muted {
        font-family: Montserrat-Regular;
        font-size: 12px;
        letter-spacing: 0.51px;
        color: #333333;
    }
}

.card-selected {
    background-color: $primary-color!important;
    .card-text {
        color: #ffffff;
    }
    .card-text-muted {
        color: #ffffff;
    }
    b {
        color: #ffffff;
    }
    .card-title {
        color: #ffffff;
    }
}

.card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

.order-status-COLLECTED,
.order-status-DELIVERED {
    background-color: green
} 

.order-status-TO_BE_PREPARED, 
.order-status-IN_PREPARATION {
    background-color: gold;
}

.order-status-ISSUING_RECEIPT,
.order-status-TO_BE_COLLECTED,
.order-status-TO_BE_DELIVERED {
    background-color: orange
}

.order-status-CANCELLED,
.order-status-ABORTED,
.order-status-REFUSED {
    background-color: red
} 

.accordion > .card > .card-header {
    margin-bottom: unset;
}

.order-header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    padding-right: 62px;
    @media screen and (max-width: 567px) {
        padding-right: unset;
    }
}

.order-delete-button {
    font-family: Montserrat-Bold;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: #ed1c1c;
    text-transform: uppercase;
    cursor: pointer;
    text-decoration: underline;
}

.order-copy-button {
    font-family: Montserrat-Bold;
    font-size: 14px;
    letter-spacing: 0.5px;
    color: $secondary-color;
    text-transform: uppercase;
    cursor: pointer;
    text-decoration: underline;
    margin-left: 25px;
}

.category-list-item {
    font-family: Montserrat-Bold;
    font-size: 14px;
    letter-spacing: 0.51px;
    color: #333333;
    cursor: pointer; 
    text-transform: uppercase;
    padding-bottom: 15px;
    display: flex;
    align-items: center;
    span {
        margin-left: 20px;
        &:hover {
            color: $secondary-color;
        }
    }
    &.favorites {
        color: $primary-color!important;
    }
    svg path {
        fill: $primary-color;
    }
}

.product-container {
    padding-left: 20px;
    padding-top: 48px;
    @media screen and (max-width: 991px) {
        padding-left: unset;
        text-align: center;
    }
}

.product-detail {
    text-align: center;
}

.product-detail-name {
    font-family: Montserrat-Bold;
    font-size: 24px;
    @media screen and (max-width: 567px) {
        font-size: 20px;
    }
    letter-spacing: 1.03px;
    color: #333333;
    margin-bottom: 13px;
}

.product-detail-promotion {
    font-family: Montserrat-Bold;
    text-transform: uppercase;
    font-size: 14px;
    line-height: 1.57;
    letter-spacing: 0.21px;
    color: $primary-color;
    margin-bottom: 25px;
}

.product-detail-netPrice {
    font-family: Montserrat-SemiBold;
    font-size: 12px;
    letter-spacing: 0.68px;
    color: #8d8c8c;
    margin-bottom: 25px;
}

.product-detail-pieces {
    @media screen and (min-width: "992px") {
        max-width: 270px;
        width: 100%;
        display: flex;
        justify-content: center;
    }
    margin-bottom: 15px;
    font-family: Montserrat-SemiBold;
    font-size: 14px;
    letter-spacing: 0.68px;
    color: #8d8c8c;
}

.product-detail {
    img {
        max-height: 58vh;
        max-width: 100%;
    }
    @media screen and (max-width: 567px) {
        img {
            max-height: 30vh;
        }
    }
    @media screen and (min-width: 568px) and (max-width: 991px) {
        img {
            max-height: 38vh;
        }
    }
}

.product-price {
    font-family: Montserrat-Bold;
    font-size: 20px;
    letter-spacing: 1.74px;
}

.product-unavaible {
    background-color: #e2e2e2;
    margin-top: 35px;
    padding: 20px;
    span {
        font-family: Montserrat-Bold;
        font-size: 14px;
        letter-spacing: 0.79px;
        text-align: center;
        color: #d81818;
        text-transform: uppercase;
    }
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
    @media screen and (max-width: 567px) {
        max-height: 30vh;
    }
    max-height: 50vh;
}

.image-gallery-slide-wrapper {
        // max-width: 700px;
        margin: 0 auto;
}

.image-gallery-icon {
    filter: none;
}

.image-gallery-left-nav .image-gallery-svg, .image-gallery-right-nav .image-gallery-svg {
    color: #dfdfdf;
    box-shadow: none;
    &:hover {
        color: $primary-color;
    }
}

.image-gallery-bullets {
    bottom: -30px;

    & .image-gallery-bullet {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background-color: #dfdfdf;
        border-radius: 50%;
        box-shadow: none;
        cursor: pointer;
        display: inline-block;
        margin: 0 5px;
        outline: none;
        padding: 5px;
        transition: background .2s ease-out;
        &:hover {
            background: $primary-color;
        }
    }

    & .image-gallery-bullet.active {
        background: $primary-color;
    }
}

.product-detail-heading {
    font-family: Montserrat-Bold;
    font-size: 14px;
    letter-spacing: 0.6px;
    color: #333333;
    text-transform: uppercase;
    margin-bottom: 5px;
}

.hr-items {
    margin: unset;
    &:last-of-type {
        display: none;
    }
}

.check-item {
    &-updated {
        font-family: Montserrat-Bold;
        font-size: 12px;
        line-height: 1.42;
        letter-spacing: 0.44px;
        text-transform: uppercase;
        color: #ffb100;
    }
    &-removed {
        font-family: Montserrat-Bold;
        font-size: 12px;
        line-height: 1.42;
        letter-spacing: 0.44px;
        text-transform: uppercase;
        color: #d81818;
    }
} 

.product-detail-ref {
    font-family: Montserrat-Regular;
    font-size: 12px;
    letter-spacing: 0.68px;
    color: #5b5b5b;
    margin-bottom: 15px;
}

.product-detail-rowNetPrice {
    display: inline-block;
    font-size: 18px;
    margin-bottom: 15px;
}

.product-detail-counter {
    max-width: 151px;
    @media screen and (max-width: "576px") {
        max-width: 130px;
    }
    width: 100%;
    display: flex;
    justify-content: space-between;
    // margin-bottom: 25px;
    &-text {
        font-family: Montserrat-Medium;
        font-size: 14px;
        text-align: center;
        color: $secondary-color;
    }
}

.product-detail-counter button {
    background: transparent;
    border: 0;
    cursor: pointer;
    padding: 0;
    height: 100%;
    text-align: center;
    color: $secondary-color;
}

.product-detail-counter input {
    width: 100%;
    max-width: 65px;
    height: 32px;
    line-height: 36px;
    margin: 0;
    padding: 0;
    border: 1px solid #ebebeb;
    border-radius: 3px;
    font-family: Montserrat-Bold;
    font-size: 1rem;
    color: $primary-color;
    &:disabled {
        background-color: white;
    }
}

.product-cart-counter {
    max-width: 270px;
    @media screen and (max-width: "991px") {
        max-width: 250px;
        margin: 0 auto;
        margin-bottom: 15px;
    }
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    button {
        width: 45px;
        height: 45px;
        border-radius: 8px;
        border: solid 1px $secondary-color;
        background-color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        svg path {
            fill: $secondary-color;
        }
    }
    input {
        font-family: Montserrat-Medium;
        color: $secondary-color;
        font-size: 16px;
        border-radius: 8px;
        border: solid 1px $primary-color;
        background-color: #ffffff;
        width: 127px;
        height: 45px;
    }
}

.checkout-container {
    background: #fbfbfb;
    font-family: Montserrat-Regular;
    padding-bottom: 49px;
}

.checkout-header {
    margin-top: 15px;
    @media screen and (max-width: "576px") {
        display: none;
    }
}

.checkout-button {
    width: 274px;
}

.checkout-controls {
    max-width: 600px;
    width: 100%;
    margin: 0 auto;
}

.checkout-controls-step {
    font-family: Montserrat-Bold;
    font-size: 14px;
    letter-spacing: 0.6px;
    color: #9c9c9c;
    pointer-events: none;
}

// .checkout-controls-step-completed {
//     color: #000;
// }

.checkout-controls-step-current {
    font-family: Montserrat-Bold;
    font-size: 14px;
    letter-spacing: 0.6px;
    color: $secondary-color;
    text-transform: uppercase;
}

.go-back-button {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: transparent;
    border: none;
    cursor: pointer;
    font-family: Montserrat-Bold;
    font-size: 12px;
    letter-spacing: normal;
    color: $primary-color;
    margin-left: 50px;
    @media screen and (max-width: "991px") {
        margin-left: 20px;
        padding-top: 40px;
    }
    padding-top: 15px;
}

.checkout-view {
    margin-top: 38px;
    max-width: 1170px;
    width: 100%;
}

.disponibilityInfo {
    font-family: Montserrat-Bold;
    font-size: 18px;
    letter-spacing: 0.77px;
    text-align: center;
    color: #333333;
    margin-bottom: 25px;
}

.disponibility-button {
    height: 130px!important;
    width: 130px!important;
    border-radius: 8px;
    border: solid 2px $secondary-color;
    background-color: #ffffff;
    color: #333333;
    cursor: pointer;
    padding: 0px;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    span {
        font-family: Montserrat-Bold;
        font-size: 16px;
        letter-spacing: 0.79px;
        text-align: center;
        color: #333333;
        text-transform: uppercase;
    }
    .day {
        font-size: 30px;
        letter-spacing: 1.49px;
    }
}

.disponibility-button-selected {
    background-color: $secondary-color;
    span {
        color: #ffffff;
    }
}

.disponibility-ranges {
    max-width: 900px;
    margin: 0 auto;
    margin-top: 39px;
    margin-bottom: 20px;
    justify-content: center;
    display: flex;
}

.disponibility-ranges-item {
    display: inline-block;
}

.pill {
    width: 195px;
    @media screen and (max-width: "991px") {
        width: 150px;
    }
    height: 45px;
    border-radius: 8px;
    border: solid 2px $secondary-color;
    background-color: #ffffff;
    font-family: Montserrat-Regular;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    font-family: Montserrat-Regular;
    font-size: 14px;
    letter-spacing: 0.1px;
    text-align: center;
    color: #333333;
    margin-bottom: 20px;
}

.pill-disabled {
    border: solid 2px #e8e8e8;
    background-color: #e8e8e8;
    pointer-events: none;
}

.pill-active {
    background: $secondary-color!important;
    border-color: $secondary-color!important;
    color: #fff;
}

.checkout-data {
    max-width: 658px;
    width: 100%;
    margin: 0 auto;
    &-title {
        font-family: Montserrat-Bold;
        font-size: 18px;
        @media screen and (max-width: "576px") {
            font-size: 16px;
        }
        line-height: 1.33;
        letter-spacing: 0.49px;
        color: #000000;
        margin-bottom: 26px;
    }
    hr {
        background-color: $secondary-color;
        margin-top: 30px;
        margin-bottom: 30px;
    }
    .checkout-textarea {
        &::placeholder {
            font-family: Montserrat-Regular;
            font-size: 14px; 
            letter-spacing: 0.6px;
            color: #bebebe;
        }   
    }
    .form-group {
        margin-bottom: unset;
    }
    .custom-control {
        min-height: unset;
    }
    .custom-control-label {
        padding-top: 2px;
        padding-left: 15px;
        font-family: Montserrat-Bold;
        font-size: 14px;
        letter-spacing: 0.7px;
        color: #000000;
    }
    .address-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .add-link {
            font-family: Montserrat-Regular;
            font-size: 12px;
            letter-spacing: 0.11px;
            color: $secondary-color;
            cursor: pointer;
            text-decoration: underline;
        }
    }
    .form-control:disabled, .form-control[readonly] {
        background-color: unset;
    }
}

.checkout-billing-address {
    margin-top: 20px;
    border-radius: 5px;
    border: solid 1px #d8d8d8;
    .internal-container {
        padding-left: 84px;
    }
    .radio-button {
        padding-left: 30px;
        padding-top: 20px;
    }
    .label {
        font-family: Montserrat-Bold;
        font-size: 12px;
        letter-spacing: 0.6px;
        color: #7a7a7a;
        padding-bottom: 11px;
    }
    .info {
        font-family: Montserrat-Bold;
        font-size: 14px;
        letter-spacing: 0.6px;
        color: #212528;
        padding-bottom: 6px;
    }
}

.checkout-summary {
    width: 100%;
    font-size: 18px;
    border-radius: 5px;
    border: solid 1px #d8d8d8;
    background-color: #fbfafa;
    padding: 24px 30px;
    font-family: Montserrat-Regular;
    font-size: 14px;
    letter-spacing: 0.7px;
    color: #000000;
    margin-bottom: 20px;
}

.checkout-summary-total {
    border-top: 1px solid #d8d8d8;
    font-family: Montserrat-Regular;
    font-size: 14px;
    letter-spacing: 0.6px;
    color: #333333;
}

.checkout-summary-total-text {
    font-family: Montserrat-Regular;
    font-size: 14px;
    letter-spacing: 0.6px;
    color: #333333;
}

.checkout-summary-total-price {
    font-family: Montserrat-Bold;
    font-size: 18px;
    letter-spacing: 0.77px;
    text-align: right;
    color: #333333;
}

.checkout-subtitle {
    font-family: Montserrat-Regular;
    font-size: 12px;
    line-height: 1.67;
    letter-spacing: 0.6px;
    color: #000000;
    a {
        color: $primary-color;
        cursor: pointer;
        &:hover {
            text-decoration: underline;
        }
    }
}

.text-strike {
    text-decoration: line-through;
    color : #7a7a7a;
    font-family: Montserrat-Regular;
}

.map-container {
    display: flex;
    justify-content: center;
}

.leaflet-container {
    z-index:1; 
    width: 85%;
    height: 325px;
}
  
.pagination {
    margin-top: 25px;
    // margin-bottom: 25px;
}

.pagination span {
    cursor: pointer;
    color: black;
    float: left;
    padding: 6px 12px;
    text-decoration: none;
    transition: background-color .3s;
    border: 1px solid #ddd;
}
  
.pagination span.active {
    color: white;
    background-color: $primary-color;
    border: 1px solid $primary-color;
}

.home-item {
    margin: 0 10px 50px;
    width: 100%;
    max-width: 235px;
    @media screen and (max-width: "576px") {
        max-width: 160px;
    }
    color: #333;
    font-family: Montserrat-Regular;
}
 
.home-item-content {
    background: #fff;
    position: relative;
}

.home-item-header {
    height: 245px;
    @media screen and (max-width: "576px") {
        height: 200px;
    }
    position: relative;
}

.home-item-header-promo {
    font-family: Montserrat-Regular;
    padding: 15px;
    position: relative;
    z-index: 1;
}

.home-item-image {
    height: 100%;
    width: 100%;
    left: 50%;
    max-height: 70%;
    max-width: 70%;
    padding: 20px 0 10px;
    position: absolute;
    text-align: center;
    top: 57%;
    -webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
}

.home-item-image img {
    max-width: 100%;
    max-height: 100%;
}

.home-item-actions {
    border-top: 1px solid #f7f7f7;
    background: #fff;
    position: relative;
}

.home-item-actions .items {
    border-bottom: 1px solid #f7f7f7; 
    height: 50px;
    border-right: 1px solid #f7f7f7;
}

.home-item-actions button {
    background: transparent;
    border: 0;
    cursor: pointer;
}

.home-item-actions span {
    color: #c1c1c1;
}

.home-button-items :hover,
.home-button-items :focus,
.home-button-items :active {
    background-color: $primary-color;
}

.home-button-items :hover span,
.home-button-items :focus span,
.home-button-items :active span {
    color: white;
}

.home-item-footer .info {
    color: #666;
    font-family: Montserrat-Bold;
    font-size: 10px;
}

.home-item-footer .price {
    font-weight: 700!important;
    text-align: center;
}
.home-categories {
    background: #fff;
    border-top: 0.4px solid rgba(0,0,0,.2);
    padding: 25px;

}

.home-categories-item {
    margin-right: 20px;
    margin-left: 20px;
    margin-bottom: 0;
    text-align: center;
    padding: 0;
    svg {
        width: 40px;
    }
    .content {
        padding-top: 10px;
    }
    .text {
        padding-top: 7px;
    }
}

.home-categories-link {
    color: #000;
    font-family: Montserrat-Bold;
    font-size: .875rem;
    text-transform: uppercase;
    text-decoration: none;
}

.home-categories-link:hover {
    color: $primary-color;
    text-decoration:none;
    cursor:pointer;
}


.popup-content {
    height: fit-content;
    max-height: calc(100vh - 52px);
    overflow-y: auto;
    box-shadow: 0 0 23px 10px rgba(0, 0, 0, 0);
    -ms-overflow-style: none; // IE and edge compatibility
    &::-webkit-scrollbar { // hide scrollbar but keep functionality
        display: none;
      }
    width: unset!important;
    @media screen and (max-width: "576px") {
        max-width: 90%;
    }
}

.popup {
    width: 720px;
    @media screen and (max-width: "768px") {
        width: 90vw;
    }
    .cta {
        @media screen and (max-width: "576px") {
            width: 150px;
        }
    }
}

.popup-header {
    margin-top: 30px;
    margin-bottom: 28px;
    font-size: 18px;
    letter-spacing: 0.77px;
    color: #333333;
    width: 100%;
    text-align: center;
    font-family: Montserrat-Bold;
    text-transform: uppercase;
}
.popup .content {
    width: 100%;
    max-width: 525px;
    margin: 0 auto;
    font-family: Montserrat-Regular;
    font-size: 18px;
    line-height: 1.39;
    letter-spacing: 0.77px;
    text-align: center;
    color: #333333;
    margin-bottom: 28px;
}
.popup .actions {
    width: 100%;
    padding: 10px 5px;
    text-align: center;
    display: flex;
    justify-content: center;
}

.popup .abort {
    background: #ffffff;
    border-radius: 8px;
    border: solid 2px $secondary-color;
    margin-right: 20px;
    span {
        color: $secondary-color;
    }
    &:hover {
        background-color: $secondary-color;
        span {
            color: #ffffff;
        }
    }
}

.popup .close {
    width: 16px;
    height: 16px;
    right: 0px;
    top: 0px;
    position: absolute;
    padding-right: 20px;
    cursor: pointer;
    color: #212528;
    opacity: unset;
    font-size: 28px;
    margin-right: 24px;
    margin-top: 24px;
}

.store-button {
    border: 0;
    background: white;
    cursor: pointer;
    font-family: Montserrat-Bold;
    text-transform: uppercase;
    font-size: 12px;
    height: 40px;
    line-height: 40px;
    width: 30%;
    box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    &-active {
        background: $primary-color;
        color: #fff;
    }
}

.store-info-title {
    display: block;
    font-family: Montserrat-Bold;
    font-size: 12px;
    text-transform: uppercase;
    cursor: pointer;
}

.store-info {
    font-family: Montserrat-Regular;
    margin-top: 22px;
    p {
        padding: 0;
        font-size: 12px;
        margin: 0 0 17px;
    }
    h4 {
        padding: 0;
        color: #a8a8a8;
        font-size: 10px;
        margin: 0 0 5px;
        text-transform: uppercase;
    }
    img {
        width: 38px;
        margin-right: 5px;
    }
}

.toast-close {
    color: #fff;
}

.toast-close :hover,
.toast-close :active,
.toast-close :focus {
    color: #fff;
}

.toast-standard {
    background-color: #333;
    color: #fff;
	border: 1px solid hsla(0,0%,100%,.1);
    justify-content: space-between;
    text-transform: uppercase;
    margin: 80px 20px 0;
	font-size: 1rem;
	z-index: 99999;
    margin-left: 0;
    padding: 23px 30px;
    width: 100%;
}

.category-wrapper {
    font-family: Montserrat-Bold;
    color: #333;
    border-radius: 8px;
    box-shadow: $card-shadow;
    background-color: #ffffff;
    padding: 22px;
    display: flex;
    align-items: center;
    margin-bottom: 21px;
    margin-top: 0;
    @media screen and (max-width: "767px") {
        margin-top: 21px;
    }
    .custom-checkbox {
        padding-left: 20px;
        width: 33%;
        margin-right: 0!important;
        @media screen and (max-width: 991px) {
            width: 42%;
            margin-bottom: 10px;
        }
        @media screen and (max-width: 375px) {
            width: 100%;

        }
    }
    .custom-control-label {
        padding-top: 2px;
    }
}

.category-tree {
    border-radius: 8px;
    box-shadow: $card-shadow;
    background-color: #ffffff;
    padding: 20px;
}
.category-all {
    font-size: 13px;
    color: $primary-color;
    text-decoration: underline;
    cursor: pointer;
    padding-bottom: 10px;
}

.category-name {
    font-family: Montserrat-Bold;
    font-size: 24px;
    letter-spacing: 1.03px;
    color: $secondary-color;
    @media screen and (max-width: "576px") {
        font-size: 22px;
    }
}


.category-container {
    padding-top: 43px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 40px;
    @media screen and (max-width: "1200px") {
        padding-left: 20px;
        padding-right: 20px;
    }
    @media screen and (max-width: "576px") {
        padding-top: 20px;
        padding-left: 10px;
        padding-right: 10px;
        width: unset;
    }
    .title {
        @media screen and (max-width: "576px") {
            font-size: 20px;
            padding-left: 10px;
            padding-right: 10px;
        }
    }
}


.category-products-container {
    margin: 0 auto;
    width: 100%;
}

.category-products {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.category-filter-item {
    margin: 8px 10px 8px 0;
    float: left;
}

.category-icon {
    width: 30px;
    height: 30px;
    margin-right: 20px;
}

.category-subcategory {
    .accordion-categories {
        cursor: pointer;
        &:hover {
            color: $primary-color;
        }
        .category-title {    
            font-family: Montserrat-Regular;
            font-size: 14px;
            text-transform: uppercase;
            display: inline-block;
            margin-bottom: 33px;
            @media screen and (max-width: "576px") {
                margin-bottom: 5px;
            }
        }
    } 
}

.category-sidebar {
    font-family: Montserrat-Bold;
    font-size: 16px;
    color: #333;
    padding: 0;
    // padding-bottom: 15px;
    @media screen and (max-width: "576px") {
        padding-bottom: unset;
    }
    list-style-type: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.category-sidebar :hover {
    @media screen and (min-width: 992px) {
        cursor: pointer;
        color: $secondary-color
    }
}

.category-sidebar-option {
    font-family: Montserrat-Bold;
    font-size: 18px;
    letter-spacing: 0.77px;
    color: #333333;
    display: inline-block;
    cursor: pointer;
    @media screen and (max-width: "576px") {
        font-size: 16px;
    }
}

.category-sidebar-option-selected {
    color: #333333;
    cursor: pointer;
    margin-bottom: 15px;
}

.category-sidebar-sub {
    list-style-type: none;
    padding-bottom: 12px;
    padding-left: 20px;
    color: #333;
    li {
        margin-bottom: 5px;
    }
}

.category-sidebar-sub :hover {
    color: $secondary-color;
    font-family: Montserrat-Bold;
}

.category-sidebar-sub-option {
    font-family: Montserrat-Regular;
    font-size: 14px;
    letter-spacing: 0.6px;
    color: #333333;
    cursor: pointer;
}

.category-sidebar-sub-option-selected {
    color: $secondary-color;
    font-family: Montserrat-Bold;
}

.category-banner {
    @media screen and (min-width: 992px) {
        height: 188px;
    }
    @media screen and (min-width: 768px) and (max-width: 991px) {
        height: 155px;
    }
    height: 110px;
    width: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
}

.cart-create-title {
    font-family: Montserrat-Bold;
    font-size: 24px;
    letter-spacing: 1.03px;
    color: #333333;
    @media screen and (max-width: "576px") {
        font-size: 20px;
        padding-top: 10px;
    }

    font-weight: 400;
    margin: 0;
    line-height: 1.35;
}

.cart-create-select {
    margin-top: 24px;
    @media screen and (max-width: "576px") {
        margin-top: 15px;
    }
    .cta {
        width: 229px;
        @media screen and (max-width: "991px") {
            width: 100%;
        }
    }
}

.dropdown-button {
    height: 45px;
    border-radius: 8px;
    border: solid 1px #d8d8d8;
    width: 100%;
    text-align: left; 
    padding-left: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-family: Montserrat-Medium;
    font-size: 14px;
    letter-spacing: 0.6px;
    color: #333333;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    cursor: pointer;
    position: relative;
    -webkit-transition: all .3s linear;
    transition: all .3s linear;
    background-color: #ffffff;

    &-link {
        color: $primary-color;
        &:hover {
            text-decoration: underline;
        }
    }
}

.dropdown-toggle::after {
    font-size: 22px;
}

.dropdown--isOpen {
    background: #ffffff;
    overflow: auto;
    max-height: 210px;
    height: auto;
}

.dropdown-items {
    position: absolute;
    left: 0;
    right: 0;
    border-radius: 8px;
    -webkit-transition: all .3s linear,max-height .6s ease-out;
    transition: all .3s linear,max-height .6s ease-out;
    background-color: #ffffff;
    overflow-x: hidden;
}

.dropdown-item {
    font-family: Montserrat-Medium;
    font-size: 14px;
    letter-spacing: 0.6px;
    color: #333333;
    padding: 7px 26px;
    cursor: pointer;
}

.dropdown-item-add {
    color: $primary-color;
}

.dropdown-items span:hover {
    background-color: #ffffff;
    color: $secondary-color;
}

.dropdown-items span:active {
    background-color: #ffffff;
    color: $secondary-color;
}

.dropdown-no-items {
    font-family: Montserrat-Medium;
    font-size: 14px;
    letter-spacing: 0.6px;
    color: $primary-color;
}

.bg {
    background-image: url("../src/images/sfondo_Sì con te.png");
    // background-image: url("https://mdbootstrap.com/img/Photos/Horizontal/Nature/full page/img(20).jpg");
    height: 80vh;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.favorite-container {
    @media screen and (min-width: 992px) {
        padding-left: 20%;
    }
}

.payments-container {
    @media screen and (min-width: 992px) {
        padding-left: 20%;
        padding-right: 20%;
    }
}

.favorite-products {
    position: relative;
    padding : 0px 0px 0px 0px;
    font-size: 14px;
    font-family: Montserrat-Bold;
}

.favorite-products img {
    max-height: 70px;
    max-width: 70px;
}

.favorite-products-text {
    top: 20px;
}

.list-title-actions {
    display: flex;
    justify-content: space-between;
}

.max-product-row {
    max-width: 1800px;
}

.modal-header {
    border-bottom: none !important;

}