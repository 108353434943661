@import '../../../scss/_variables.scss';
.card-product {
    width: 205px;
    max-width: 205px;
    height: 344px;
    border-radius: 8px;
    // box-shadow: 0 0 14px 0 rgba(101, 179, 46, 0.23);
    box-shadow: $card-shadow;
    background-color: #ffffff;
    margin: 0 10px 50px;

    @media screen and (min-width: 351px) and (max-width: 361px) {
        max-width: 160px;
        margin: 0 5px 50px;
    }
    @media screen and (min-width: 361px) and (max-width: 390px) {
        max-width: 167px;
        margin: 0 5px 50px;
    }
    @media screen and (min-width: 390px) and (max-width: 539px) {
        max-width: 180px;
        margin: 0 10px 50px;
    }
    &:hover {
        box-shadow: 0 0 24px 0 hsla(357,100%,70%,.8);
    }

    &-container {
        position: relative;
        width: 100%;
        height: 122px;
        text-align: center;

    }

    &-image {
        img {
            @media screen and (max-width: "576px") {
                max-height: 100px;
                max-width: 100px;
            }
            text-align: center;
            margin-top: 22px;
            max-height: 122px;
            max-width: 122px;
            z-index: 1;
        }
    }

    &-promo {
        position: absolute;
        z-index: 2;
        height: 26px;
        top: 14px;
        text-transform: uppercase;
        width: 115px;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        background-color: $secondary-color;
        span {
            font-family: Montserrat-Bold;
            font-size: 10px;
            line-height: 2.2;
            letter-spacing: 0.4px;
            color: #ffffff;
        }
    }
    &-promo-mxn {
        position: absolute;
        z-index: 2;
        top: 0px;
        left: 5px;
        svg {
            width: 55px;
            height: auto;
        }
    }
    &-details {
        margin-top: 25px;
        padding-right: 10px;
        padding-left: 10px;
        height: 66px;
        span {
            font-stretch: normal;
            font-style: normal;
            color: #212529;
            display: block;
            display: -webkit-box;
            text-overflow: ellipsis;
            overflow: hidden;
            word-wrap: break-word;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            font-family: Montserrat-Bold;
            font-size: 14px;
            letter-spacing: 0.6px;
            color: #333333;

        }
    }
    &-um {
        margin-top: 6px;
        padding-left: 10px;
        padding-right: 19px;
        @media screen and (max-width: 576px) {
            padding-right: 10px;
        }
        height: 13px;
        font-family: Montserrat-Regular;
        font-size: 10px;
        letter-spacing: 0.87px;
        color: #666666;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .icons {
            float: right;
        }
        svg {
            width: 30px;
            @media screen and (max-width: 576px) {
                width: 25px;
            }
        }
    }
    &-prices {
        padding-left: 10px;
        padding-right: 10px;
        margin-top: 11px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .strike {
            height: 18px;
            font-family: Montserrat-Regular;
            font-size: 14px;
            letter-spacing: 1.22px;
            color: #7a7a7a;
        }
        .discount {
            font-family: Montserrat-ExtraBold;
            font-size: 14px;
            color: #f80000;
        }
        .netTotal {
            font-family: Montserrat-Bold;
            font-size: 14px;
            text-align: right;
            color: #333333;
        }
    }
    &-buttons {
        padding-left: 10px;
        padding-right: 10px;
        // margin-top: 16px;
        padding-bottom: 13px;

        .slist {
            width: 45px;
            @media screen and (max-width: "576px") {
                width: 70px;
            }
            height: 44px;
            svg {
                width: 100%;
                height: 23px;
                margin-bottom: 3px;
            }
        }
        .cart {
            margin-left: 15px;
            height: 44px;
            width: 150px;
            @media screen and (max-width: "576px") {
                width: 70px;
            }
            svg {
                width: 18px;
                height: auto;
            }
        }
    }
     
    &-unavailable {
        margin-top: 16px;
        padding-left: 6px;
        padding-right: 6px;
        .unavailable-content {
            border-radius: 8px;
            background-color: #f2f2f2;
            text-align: center;
            padding: 5px;
            line-height: 1.2;
            span {
                font-size: 12px;
                @media screen and (max-width: "576px") {
                    font-size: 10px;
                }
                color: #d81818;
                text-transform: uppercase;
                font-family: FuturaStd-Book;
                letter-spacing: 1.5px;
            }
        }
    }
    &-icons {
        padding-left: 13px;
        width: 30px;
        height: 30px;
    }
}