.privacy-policy {
    .policy-heading {
      font-family: Montserrat-Bold;
      font-size: 24px;
      letter-spacing: 1.03px;
      color: #333333;
      margin-bottom: 13px;
    }
    .policy-title{
      display: block;
      font-family: Montserrat-Bold;
      font-size: 14px;
      cursor: pointer;
      padding: 15px 0px;
    }
    .policy-subtitle{
      display: block;
      font-family: Montserrat-Bold;
      font-size: 14px;
      cursor: pointer;
      padding: 5px 0px;
    }
    .policy-terms {
      font-family: Montserrat-Regular;
      font-size: 14px;
      letter-spacing: 0.6px;
      color: #333333;
      padding: 5px;
    }
  }
  
  