@import "../../../scss/variables";

.profile-addresses {
    margin: 0 auto;
    margin-left: 50px;
    @media screen and (max-width: 991px) {
        margin-left: unset;
    }
    border-radius: 8px;
    padding: 20px;
    box-shadow: $card-shadow;
    background-color: #ffffff;
    .add {
        width: 100%;
        text-transform: uppercase;
        
        height: 122px;
        border-radius: 8px;
        border: dashed 2px $primary-color;
        background-color: #f1f0ef;
        .text {
            padding-left: 14px;
            font-family: Montserrat-Bold;
            font-size: 18px;
            letter-spacing: 0.77px;
            text-align: center;
            color: $primary-color;
        }
    }

    &-section {
        font-family: Montserrat-Bold;
        font-size: 18px;
        letter-spacing: 0.77px;
        color: #333333;
        margin-bottom: 21px;
        text-transform: uppercase;
    }

    hr {
        width: 100%;
        height: 2px;
        background-color: #333333;
        margin-top: 40px;
        margin-bottom: 21px;
    }

    &-title {
        font-family: Montserrat-Bold;
        font-size: 14px;
        letter-spacing: 0.6px;
        color: #333333;
        text-transform: uppercase;
        margin-bottom: 21px;
    }

    &-card {
        height: 215px;
        @media screen and (max-width: "576px") {
            height: unset;
        }
        .address-row {
            padding-bottom: 20px;
        }
        .address-header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 21px;
            align-items: center;
            .address-title {
                font-family: Montserrat-Bold;
                font-size: 12px;
                letter-spacing: 0.51px;
                color: #333333;
                text-transform: uppercase;
            }
            .update-button {
                border: none;
                background-color: #ffffff;
                svg {
                    path {
                        fill: $primary-color;
                    }
                }
                span {
                    padding-left: 5px;
                    font-family: Montserrat-Regular;
                    font-size: 12px;
                    letter-spacing: 0.08px;
                    color: #333333;
                }
            }
        }
    }

    &-billing {
        border-radius: 15px;
        box-shadow: $card-shadow;
        background-color: #ffffff;
        margin-bottom: 25px;
        .address-row {
            padding-left: 20px;
            padding-right: 20px;
            padding-bottom: 20px;
        }
        .address-header {
            display: flex;
            justify-content: space-between;
            padding-bottom: 29px;
            .address-title {
                padding-top: 20px;
                padding-left: 20px;
                font-family: FuturaStd-Bold;
                font-size: 16px;
                color: #212528;
            }
            .update-button {
                width: 160px;
                height: 45px;
                border-radius: 0px 15px;
                border-width: 0em;
                background-color: $address-button;
                svg {
                    path {
                        fill: #ff0000;
                    }
                }
                span {
                    padding-left: 5px;
                    font-family: Futura-Medium;
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 0.08px;
                    color: #ff0000;
                    text-transform: uppercase;
                }
            }
        }
    }
}